import React from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';
import { features } from 'gatsby-env-variables';

import { isAuthenticated } from 'services/authentication-service';

import LandingNavigation from 'components/navigation/side-bar/navigation-factory/landing';
import SupportNavigation from 'components/navigation/side-bar/navigation-factory/support';
import LoginNavigation from 'components/navigation/side-bar/navigation-factory/login';
import RegisterNavigation from 'components/navigation/side-bar/navigation-factory/register';
import ApplicationHome from 'components/navigation/side-bar/navigation-factory/home';
import ContactNavigation from 'components/navigation/side-bar/navigation-factory/contact';
import ValuesNavigation from 'components/navigation/side-bar/navigation-factory/values';

import { Grid } from '@material-ui/core';

import useStyles from './styles';

const NoAuthSideNavigation = ({ toggle, login = true, register = true }) => {
  const styles = useStyles();

  const authenticated = isAuthenticated();
  const { showValues = false } = features;

  return (
  <div className={classNames(styles.drawerInner, !toggle && styles.drawerPaperClose)}>
    <Grid spacing={2}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
      >

    </Grid>
    <div className={classNames(toggle ? styles.menuContainer : styles.paddMenuContainer)}>
      {authenticated ? (
        <ApplicationHome/>
      ) : (
        <LandingNavigation />
      )}
      <SupportNavigation />
      <ContactNavigation/>
      {!authenticated && (
        <>
        {login && <LoginNavigation />}
        {register && <RegisterNavigation />}
        </>
      )}
      {showValues && <ValuesNavigation/>}
    </div>
  </div>
  );
};

NoAuthSideNavigation.propTypes = {
  toggle: bool.isRequired,
  login: bool,
  register: bool,
};

export default NoAuthSideNavigation;
