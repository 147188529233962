import React from 'react';
import { LANDING_PAGE } from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import BaseNavigation from '../base-nav';

import useStyles from '../styles';

const LandingNavigation = () => {
  const styles = useStyles();

  return (
    <BaseNavigation
      classes={styles}
      description="Home"
      iconChild={
        <FontAwesomeIcon icon={faHome} size="2x" className={styles.icon}/>
      }
      navigation={LANDING_PAGE}
      />
  );
};

export default LandingNavigation;
