import React from 'react';
import { navigate } from 'gatsby';
import { LOGIN_ROUTE } from 'constants/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-light-svg-icons';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import useStyles from './styles';

const LoyaltyNavigation = () => {
  const styles = useStyles();

  return (
    <ListItem
      button
      onClick={() => navigate(LOGIN_ROUTE)}
      className={styles.listLinkItem}
      >
      <ListItemIcon className={styles.iconWrapper}>
        <FontAwesomeIcon icon={faSignIn} size="2x" className={styles.icon}/>
      </ListItemIcon>
      <ListItemText primary="Login" />
    </ListItem>
  );
};

export default LoyaltyNavigation;
