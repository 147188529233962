import React, { useState } from 'react';

import RegisterNavBar from 'components/navigation/nav-bar/register-nav-bar';
import NoAuthSideBar from 'components/navigation/side-bar/no-auth-side-bar';

const RegsiterPageNavigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
        <>
          <RegisterNavBar
            showNav
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            />
            <NoAuthSideBar
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              login={false}/>
        </>
  );
};
export default RegsiterPageNavigation;
