import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import loadable from '@loadable/component';
import { navigate, Link } from 'gatsby';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-light-svg-icons';
import { faBars } from '@fortawesome/pro-regular-svg-icons';

import { LOGIN_ROUTE, LANDING_PAGE } from 'constants/navigation';

import useStyles, { useAppBarClasses } from './styles';
import svg from './tl_dark.svg';

const AppBar = loadable(() => import('@material-ui/core/AppBar'));
const Toolbar = loadable(() => import('@material-ui/core/Toolbar'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const Button = loadable(() => import('@material-ui/core/Button'));
const IconButton = loadable(() => import('@material-ui/core/IconButton'));

const RegisterPageNavigation = ({
  menuOpen,
  setMenuOpen,
}) => {
  const styles = useStyles();
  const appBarClasses = useAppBarClasses();
  const [disabled, setDisabled] = useState(false);

  const homeRoute = LANDING_PAGE;

  return (
    <AppBar
      elevation={2}
      position="fixed"
      classes={appBarClasses}
      className={classNames(
        styles.appBar, (menuOpen) && styles.appBarShift,
      )}
      >
      <Toolbar>

        <IconButton
          color="inherit"
          aria-label="open menu"
          onClick={() => setMenuOpen(!menuOpen)}>
          <FontAwesomeIcon icon={faBars} />
        </IconButton>
        <Hidden smUp>
          <Link className={styles.logoText} to={homeRoute}>
            <img
              className={styles.logo}
              src={svg}
              alt="Total Loyalty"
              height="25"
              width="25"
            />
          </Link>
        </Hidden>
        <Hidden xsDown>
          <Link className={styles.logoText} to={homeRoute}>
            <img
              className={styles.logo}
              src={svg}
              alt="Total Loyalty"
              height="25"
              width="25"
            />
            </Link>
        <Typography variant="subtitle1" component="h6">
          <Link className={styles.logoText} to={homeRoute}>
          TOTAL LOYALTY
          </Link>
        </Typography>
        </Hidden>

        <div className={styles.landingBarButton}>
          <Button
           aria-label="Login"
           onClick={() => {
             setDisabled(true);
             navigate(LOGIN_ROUTE);
           }}
           className={styles.firstButton}
           size="large"
           variant="outlined"
           disabled={disabled}
          >
          <FontAwesomeIcon
            icon={faSignIn}
            size="2x"
            className={styles.navIcon}
            />
            <Hidden xsDown>Login</Hidden>
          </Button>
        </div>

      </Toolbar>
    </AppBar>
  );
};

RegisterPageNavigation.propTypes = {
  menuOpen: bool.isRequired,
  setMenuOpen: func.isRequired,
};

export default RegisterPageNavigation;
