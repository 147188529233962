import React from 'react';
import { navigate } from 'gatsby';
import { REGISTRATION_START } from 'constants/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmilePlus } from '@fortawesome/pro-light-svg-icons';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import useStyles from './styles';

const LoyaltyNavigation = () => {
  const styles = useStyles();

  return (
    <ListItem
      button
      onClick={() => navigate(REGISTRATION_START)}
      className={styles.listLinkItem}
      >
      <ListItemIcon className={styles.iconWrapper}>
        <FontAwesomeIcon icon={faSmilePlus} size="2x" className={styles.icon}/>
      </ListItemIcon>
      <ListItemText primary="Register" />
    </ListItem>
  );
};

export default LoyaltyNavigation;
